<template>
  <div class="accountset">
    <userheader menucurrent=2 />
    <div class="main-div">
      <div class="account-content wrap">
        <!-- 左侧导航 -->
        <userleft currindex=0 />

        <div class="main-box">
          <div class="account-main">
            <div class="item-block">
              <h3 class="title">修改手机号<em></em></h3>
              <div class="info-cnt">
                <div class="content-rb resetpwd">
                  <el-form :rules="rules" :model="ruleForm" ref="ruleForm" label-width="0" class="demo-ruleForm">
                    <div class="container">
                      <el-form-item label="新手机号" prop="newUserMobile">
                        <el-input style="width:300px" v-model="ruleForm.newUserMobile" class="input-number" type="text"
                          placeholder="请输入手机号码" @input="changeInput()" />
                      </el-form-item>
                      <el-form-item prop="verifCode" label="验证码">
                        <el-input style="width:300px" label="验证码" class="input-number" type="text"
                          placeholder="请输入验证码" v-model="ruleForm.verifCode" />
                        <span @click="getCode('ruleForm')">{{codeMsg}}</span>
                      </el-form-item>
                  
                      <div class="sub-btn">
                        <el-button @click="submit('ruleForm')">提交</el-button>
                      </div>
                    </div>
                  </el-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <userfooter />
  </div>
</template>

<script>
import userheader from '@/components/userheader.vue';
import userfooter from '@/components/userfooter.vue';
import userleft from '@/components/userleft.vue';
import {
  usersendchangeusermobilesms,
  userchangeusermobile,
} from '@/api/user';
import Cookies from 'js-cookie';
import {removeCookieByName} from '@/utils/helper';
export default {
  name: 'App',
  components: {
    userheader: userheader,
    userfooter: userfooter,
    userleft: userleft,
  },
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号不能为空'));
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        if (reg.test(value)) {
          return callback();
        } else {
          return callback(new Error('请输入正确的手机号'));
        }
      }
    };
    return {
      labelPosition: 'right',
      firstShow: true,
      show: false,
      height: '600',
      // 确认密码
      rules: {
        newUserMobile: [{ validator: checkPhone, trigger: 'blur' }],
        verifCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
      },
      ruleForm: {
        newUserMobile: '',
        verifCode: '',
      },
      codeMsg: '获取验证码',
      second: 60,
      timer: null,
    };
  },
  methods: {
    changeInput() {
      var phone = /^[1]([3-9])[0-9]{9}$/;
      this.dbget = false;
      if (phone.test(this.ruleForm.newUserMobile)) {
        this.dbget = true;
        this.num = 1;
      }
    },

    getCode(formName) {
      this.dbget = true;
      if (this.ruleForm.newUserMobile == '') {
        this.$message.error('请输入手机号');
        return;
      }
      if (this.second != 60) {
        this.$message.error(`请${this.second}秒后重试`);
        return;
      }
      usersendchangeusermobilesms({ userMobile: this.ruleForm.newUserMobile }).then((res) => {
        if (res.data.code === 1) {
          this.$message({
            message: '发送成功',
            type: 'success',
          });
          this.codeMsg = `${this.second}s后重试`;
          this.timer = setInterval(() => {
            this.second--;
            this.codeMsg = `${this.second}s后重试`;
            if (this.second == 0) {
              clearInterval(this.timer);
              this.second = 60;
              this.codeMsg = '获取验证码';
            }
          }, 1000);
        } else {
          this.$message.error(res.data.message);
          //this.phone = false;
        }
      });
      return;
    },

    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var form = {
            newUserMobile: this.ruleForm.newUserMobile,
            verifCode: this.ruleForm.verifCode,
          };
          var that = this;
          userchangeusermobile(form).then((res) => {
            if (res.data.code === 1) {
              this.$message.success('修改成功');
              this.removeCookies();
              setTimeout(function () {
                 window.location.href="/"
              }, 300);
            } else {
              this.$message.error(res.data.message);
            }
          });
          return;
        } else {
          return false;
        }
      });
    },
     removeCookies() {
      removeCookieByName('access_token');
      removeCookieByName('user_account');
      removeCookieByName('refresh_token');
      removeCookieByName('user_id');
      removeCookieByName('iscompanyauthsuccess');
    },
  },
  mounted() {
    screenLeft;
  },
};
</script>
<style  scoped >
/**公共css开始**/
body {
  background-color: #eaecef;
  /* height: 100%; */
}
.wrap {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
}
.accountset {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  position: relative;
}
.accountset .main-div {
  margin-top: 40px;
  padding-bottom: 210px;
}
.accountset .account-content {
  padding: 20px 0 0;
  overflow: hidden;
  position: relative;
}

.acount-card {
  background-position: 0 -172px;
  background-image: url('../../assets/img/account-ico.png');
}
.accountset .main-box {
  float: right;
  width: 940px;
}
.accountset .item-block {
  padding: 20px;
  min-height: 720px;
  background-color: #fff;
}
.accountset .item-block .title {
  font-size: 28px;
  color: #404040;
  line-height: 46px;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 400;
}
.accountset .item-block .title em {
  font-size: 16px;
  color: #404040;
}
.accountset .info-cnt {
  padding: 30px 0;
}

.accountset .info-cnt >>> .el-form-item__label {
  color: #a0a0a0;
  min-width: 84px;
  font-size: 16px;
}

.accountset .info-cnt >>> .el-form-item__content button {
  background-image: linear-gradient(196deg, #5aa5ff, #0d7bff);
  color: #fff;
  border: none;
}
.accountset >>> form.elform {
  width: 460px;
}
/**公共css结束**/

.accountset >>> form.elform .phone .el-input {
  width: 220px;
  margin-right: 10px;
}
.accountset .info-cnt >>> .el-form-item__content button.sendcode {
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
}

.accountset .info-cnt .input-number {
  height: 42px;
  border: none;
  /* float: left; */
}
.accountset .info-cnt .message-number {
  margin-top: 3px;
}
.accountset .info-cnt .container-zh-right {
  float: left;
  position: relative;
}
.accountset .info-cnt span {

  font-size: 14px;
  line-height: 31px;
  margin-right: 10px;
  color: #3194fd;
  cursor: pointer;
  top: 0;
  margin-left: 5px;
  border: 1px solid #ddd;
  padding: 3px 10px;
  padding: 11px;
  border-radius: 5px;
  color: #8a8a8a;
  border: 1px solid #dcdfe6;
  color: #606266;
}
.accountset .info-cnt .sub-btn {
      text-align: left;
    margin-left: 83px;
}
.accountset .info-cnt .btn.sel {
  background-color: #228bff;
  border: 0;
  color: #fff;
}
.accountset .info-cnt .btn {
  width: 200px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  background: #fff;
  cursor: pointer;
}
input {
  outline: 0;
}

.el-input__suffix {
  z-index: 100 !important;
}

.accountset .info-cnt .el-button {
  background-image: linear-gradient(196deg, #5aa5ff, #0d7bff);
  color: #fff;
  border: none;
  width: 120px;
}
.account-content >>>.el-form-item__error{
  left: 84px;
}
</style>
